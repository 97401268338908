/**
 * Processes raw data and calculates various metrics for sales analysis.
 * @param {Object} rawData - The raw data containing sales information.
 * @returns {Object} Processed data including sales representatives, summaries, and performance metrics.
 */
export function processData(rawData) {
    // Extract data from rawData, defaulting to an empty array if not present
    const data = rawData?.AllCXTicketby_month ?? [];
    
    // Update salesrep for each entity to ensure consistency
    const processedData = updateSalesRepForEntities(data);
    
    // Extract unique sales representatives for individual analysis
    const salesReps = [...new Set(processedData?.map(item => item?.salesrep) ?? [])];
  
    // Initialize objects to store summaries and store distributions for each sales rep
    const summaries = {};
    const storeDistributions = {};

    // Calculate summaries and store distributions for each sales rep
    salesReps.forEach(rep => {
        summaries[rep] = calculateSummary(processedData, rep);
        storeDistributions[rep] = calculateStoreDistribution(processedData, rep);
    });

    // Calculate summary and store distribution for all sales reps combined
    summaries['All'] = calculateSummary(processedData);
    storeDistributions['All'] = calculateStoreDistribution(processedData);

    // Calculate performance by account and account growth once
    const performanceByAccount = calculatePerformanceByAccount(processedData);
    const accountGrowth = calculateAccountGrowth(processedData);

    // Return an object containing all processed data and calculated metrics
    return {
        salesReps,
        rawData: processedData,
        summaries,
        performanceByAccount,
        storeDistribution: storeDistributions,
        accountGrowth
    };
}

/**
 * Calculates the category of an account based on its L12M (Last 12 Months) sales.
 * @param {number} l12m - The Last 12 Months sales amount.
 * @returns {number} The category number (1-10) based on sales thresholds.
 */
function calculateCategory(l12m) {
    // Determine category based on L12M sales thresholds
    if (l12m >= 100000) return 1;
    if (l12m >= 80000) return 2;
    if (l12m >= 60000) return 3;
    if (l12m >= 40000) return 4;
    if (l12m >= 30000) return 5;
    if (l12m >= 20000) return 6;
    if (l12m >= 15000) return 7;
    if (l12m >= 10000) return 8;
    if (l12m >= 5000) return 9;
    return 10; // Default category for sales below 5000
}

/**
 * Updates the sales representative for each entity to the most recent one.
 * @param {Array} data - The raw sales data.
 * @returns {Array} Updated data with consistent sales rep for each entity.
 */
function updateSalesRepForEntities(data) {
    // Group data by entity
    const groupedByEntity = data?.reduce((acc, item) => {
        if (!acc[item?.entity]) {
            acc[item?.entity] = [];
        }
        acc[item?.entity].push(item);
        return acc;
    }, {});

    // Update sales rep for each entity group
    Object.values(groupedByEntity).forEach(entityData => {
        // Sort entity data by date in descending order
        entityData.sort((a, b) => parseDate(b?.trandate) - parseDate(a?.trandate));
        // Get the most recent sales rep
        const latestSalesRep = entityData[0]?.salesrep;
        // Update all records for this entity with the latest sales rep
        entityData.forEach(item => {
            item.salesrep = latestSalesRep;
        });
    });

    // Flatten the grouped data back into an array
    return Object.values(groupedByEntity).flat();
}

/**
 * Parses a date string into a Date object.
 * @param {string} dateString - The date string in 'YYYY-MM' format.
 * @returns {Date|null} The parsed Date object or null if invalid.
 */
function parseDate(dateString) {
    if (!dateString) return null;
    const [year, month] = dateString?.split('-')?.map(Number);
    return new Date(year, month - 1, 1); // Month is 0-indexed in Date constructor
}

/**
 * Parses an amount string into a number.
 * @param {string} amountString - The amount as a string.
 * @returns {number} The parsed amount or 0 if invalid.
 */
function parseAmount(amountString) {
    return amountString ? parseFloat(amountString) || 0 : 0;
}

/**
 * Calculates summary metrics for a given dataset and sales rep.
 * @param {Array} data - The sales data.
 * @param {string} [selectedSalesRep=''] - The selected sales rep (optional).
 * @returns {Object} Summary metrics including percentage changes.
 */
function calculateSummary(data, selectedSalesRep = '') {
    // Get current date and calculate relevant date ranges
    const currentDate = new Date();
    const lastCompleteMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const oneYearAgo = new Date(lastCompleteMonth.getFullYear() - 1, lastCompleteMonth.getMonth(), 1);
    const twoYearsAgo = new Date(lastCompleteMonth.getFullYear() - 2, lastCompleteMonth.getMonth(), 1);

    // Filter data for selected sales rep if provided
    const filteredData = selectedSalesRep ? data?.filter(item => item?.salesrep === selectedSalesRep) : data;

    // Calculate summary metrics
    const result = filteredData?.reduce((acc, item) => {
        const date = parseDate(item?.trandate);
        const amount = parseAmount(item?.netamount);

        // Last 12 months
        if (date >= oneYearAgo && date < lastCompleteMonth) {
            acc.l12m += amount;
        }
        // Previous 12 months
        if (date >= twoYearsAgo && date < oneYearAgo) {
            acc.p12m += amount;
        }
        // Recent quarter
        if (date >= new Date(lastCompleteMonth.getFullYear(), lastCompleteMonth.getMonth() - 3, 1) && date < lastCompleteMonth) {
            acc.rq += amount;
        }
        // Last year's recent quarter
        if (date >= new Date(lastCompleteMonth.getFullYear() - 1, lastCompleteMonth.getMonth() - 3, 1) &&
            date < new Date(lastCompleteMonth.getFullYear() - 1, lastCompleteMonth.getMonth(), 1)) {
            acc.lyrq += amount;
        }
        // Last month
        if (date?.getFullYear() === lastCompleteMonth.getFullYear() && date?.getMonth() === lastCompleteMonth.getMonth() - 1) {
            acc.lm += amount;
        }
        // Last year's last month
        if (date?.getFullYear() === lastCompleteMonth.getFullYear() - 1 && date?.getMonth() === lastCompleteMonth.getMonth() - 1) {
            acc.lylm += amount;
        }
        return acc;
    }, { l12m: 0, p12m: 0, rq: 0, lyrq: 0, lm: 0, lylm: 0 });

    // Calculate percentage changes
    const calculatePercentageChange = (current, previous) => (previous === 0) ? 0 : (current - previous) / previous;

    return {
        l12mVsP12m: calculatePercentageChange(result?.l12m, result?.p12m),
        rqVsLyrq: calculatePercentageChange(result?.rq, result?.lyrq),
        lmVsLylm: calculatePercentageChange(result?.lm, result?.lylm)
    };
}

/**
 * Determines which quarter a given date belongs to relative to the last complete month.
 * @param {Date} date - The date to check.
 * @param {Date} lastCompleteMonth - The last complete month.
 * @returns {string|null} The quarter identifier ('lrq', 'q1', 'q2', 'q3', 'q4') or null.
 */
function calculateQuarterlyData(date, lastCompleteMonth) {
    const monthsDiff = (lastCompleteMonth?.getFullYear() - date?.getFullYear()) * 12 + lastCompleteMonth?.getMonth() - date?.getMonth();
    if (monthsDiff >= 0 && monthsDiff < 3) return 'lrq';
    if (monthsDiff >= 3 && monthsDiff < 6) return 'q1';
    if (monthsDiff >= 6 && monthsDiff < 9) return 'q2';
    if (monthsDiff >= 9 && monthsDiff < 12) return 'q3';
    if (monthsDiff >= 12 && monthsDiff < 15) return 'q4';
    return null;
}

/**
 * Calculates performance metrics for each account.
 * @param {Array} data - The sales data.
 * @returns {Array} An array of performance metrics for each account.
 */
function calculatePerformanceByAccount(data) {
    const currentDate = new Date();
    const lastCompleteMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const oneYearAgo = new Date(lastCompleteMonth.getFullYear() - 1, lastCompleteMonth.getMonth(), 1);
    const twoYearsAgo = new Date(lastCompleteMonth.getFullYear() - 2, lastCompleteMonth.getMonth(), 1);

    // Aggregate data by account
    const accountPerformance = data?.reduce((acc, item) => {
        const date = parseDate(item?.trandate);
        const amount = parseAmount(item?.netamount);
        const account = item?.entity;

        // Initialize account data if not present
        if (!acc[account]) {
            acc[account] = { 
                l12m: 0, p12m: 0, rq: 0, lyrq: 0, lm: 0, lylm: 0, 
                lrq: 0, q1: 0, q2: 0, q3: 0, q4: 0,
                salesRep: item?.salesrep, 
                cohort: item?.custentity_cohort || '', 
                goal: item?.SalesGoal || '', 
                top20: item?.custentity_top20 || '', 
                favorite: item?.custentity_favforsummarypage || false,
                dateOfStock: item?.custentitylast_stock_purchase_date || ''
            };
        }

        // Calculate metrics for different time periods
        if (date >= oneYearAgo && date < lastCompleteMonth) acc[account].l12m += amount;
        if (date >= twoYearsAgo && date < oneYearAgo) acc[account].p12m += amount;
        
        // Calculate quarterly data
        const quarter = calculateQuarterlyData(date, lastCompleteMonth);
        if (quarter) acc[account][quarter] += amount;

        // Calculate recent quarter, last year's recent quarter, last month, and last year's last month
        if (date >= new Date(lastCompleteMonth.getFullYear(), lastCompleteMonth.getMonth() - 3, 1) && date < lastCompleteMonth) acc[account].rq += amount;
        if (date >= new Date(lastCompleteMonth.getFullYear() - 1, lastCompleteMonth.getMonth() - 3, 1) && date < new Date(lastCompleteMonth.getFullYear() - 1, lastCompleteMonth.getMonth(), 1)) acc[account].lyrq += amount;
        if (date?.getFullYear() === lastCompleteMonth.getFullYear() && date?.getMonth() === lastCompleteMonth.getMonth() - 1) acc[account].lm += amount;
        if (date?.getFullYear() === lastCompleteMonth.getFullYear() - 1 && date?.getMonth() === lastCompleteMonth.getMonth() - 1) acc[account].lylm += amount;

        return acc;
    }, {});

    // Calculate percentage changes
    const calculatePercentageChange = (current, previous) => (previous === 0) ? 0 : (current - previous) / previous;

    // Transform aggregated data into final performance metrics
    const performanceData = Object.entries(accountPerformance)?.map(([entity, data]) => {
        const lrqCategory = calculateCategory(data?.lrq);
        const q1Category = calculateCategory(data?.q1);
        const lrqVsQ1 = q1Category - lrqCategory;
        return {
            entity,
            l12m: data?.l12m,
            l12mVsP12m: calculatePercentageChange(data?.l12m, data?.p12m),
            p12m: data?.p12m,
            lrq: lrqCategory,
            q1: q1Category,
            q2: calculateCategory(data?.q2),
            q3: calculateCategory(data?.q3),
            q4: calculateCategory(data?.q4),
            rq: data?.rq,
            lyrq: data?.lyrq,
            lm: data?.lm,
            lylm: data?.lylm,
            cohort: data?.cohort,
            goal: data?.goal,
            top20: data?.top20,
            rqVsLyrq: calculatePercentageChange(data?.rq, data?.lyrq),
            lmVsLylm: calculatePercentageChange(data?.lm, data?.lylm),
            lrqVsQ1: lrqVsQ1,
            dateOfStock: data?.dateOfStock,
            category: calculateCategory(data?.l12m),
            salesRep: data?.salesRep,
            favorite: data?.favorite
        };
    });

    //TODO: Check if Top20 should be calculated based on l12m or p12m
    // const top20Count = Math.ceil(performanceData.length * 0.2);
    // performanceData.slice(0, top20Count).forEach(account => account.top20 = true);

    return performanceData;
}

/**
 * Calculates store distribution across different quarters and categories.
 * @param {Array} data - The sales data.
 * @param {string} [selectedSalesRep=''] - The selected sales rep (optional).
 * @returns {Object} Distribution of stores across quarters and categories.
 */
function calculateStoreDistribution(data, selectedSalesRep = '') {
    const currentDate = new Date();
    const lastCompleteMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1); // Last complete month

    // Filter data based on selected sales rep
    const filteredData = selectedSalesRep ? data?.filter(item => item?.salesrep === selectedSalesRep) : data;

    // Initialize distribution object with arrays for each quarter
    const distribution = {
        LRQ: Array(10).fill(0),
        Q1: Array(10).fill(0),
        Q2: Array(10).fill(0),
        Q3: Array(10).fill(0),
        Q4: Array(10).fill(0)
    };

    // Group data by account and calculate quarterly sales
    const accountData = filteredData?.reduce((acc, item) => {
        if (!acc[item?.entity]) acc[item?.entity] = { LRQ: 0, Q1: 0, Q2: 0, Q3: 0, Q4: 0 };
        const date = parseDate(item?.trandate);
        const amount = parseAmount(item?.netamount);

        // Determine quarter and add amount
        const quarter = calculateQuarterlyData(date, lastCompleteMonth);
        if (quarter) acc[item?.entity][quarter?.toUpperCase()] += amount;

        return acc;
    }, {});

    // Calculate distribution based on categories
    Object.values(accountData).forEach(account => {
        ['LRQ', 'Q1', 'Q2', 'Q3', 'Q4'].forEach(quarter => {
            const category = calculateCategory(account[quarter]);
            distribution[quarter][category - 1]++;
        });
    });

    return distribution;
}

function calculateAccountGrowth(data) {
    const currentDate = new Date();
    const lastCompleteMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

    const accountGrowth = data.reduce((acc, item) => {
        const rep = item?.salesrep;
        const date = parseDate(item?.trandate);
        const amount = parseAmount(item?.netamount);

        if (!acc[rep]) {
            acc[rep] = { salesRep: rep, lrq: 0, q1: 0, accounts: {} };
        }

        const quarter = calculateQuarterlyData(date, lastCompleteMonth);
        if (quarter === 'lrq' || quarter === 'q1') {
            acc[rep][quarter] += amount;
            if (!acc[rep].accounts[item.entity]) {
                acc[rep].accounts[item.entity] = { lrq: 0, q1: 0 };
            }
            acc[rep].accounts[item.entity][quarter] += amount;
        }

        return acc;
    }, {});

    const growthSummary = Object.values(accountGrowth).map(({ salesRep, accounts }) => {
        let netCategoryChange = 0;
        Object.values(accounts).forEach(account => {
            const lrqCategory = calculateCategory(account.lrq);
            const q1Category = calculateCategory(account.q1);
            netCategoryChange += q1Category - lrqCategory;
        });

        return {
            salesRep,
            netCategoryChange
        };
    });

    // Add 'All' summary
    const allNetCategoryChange = growthSummary.reduce((sum, rep) => sum + rep.netCategoryChange, 0);
    growthSummary.unshift({ salesRep: 'All', netCategoryChange: allNetCategoryChange });

    return growthSummary;
}

export function processStockSalesSummary(rawData) {
    const stockSales = rawData.StockSales || [];
    const currentDate = new Date();

    // Get unique list of sales reps
    const salesReps = [...new Set(stockSales.map(sale => sale.salesrep))];

    // Calculate summary for each sales rep and 'All'
    const stockSalesSummary = {};
    salesReps.forEach(rep => {
        stockSalesSummary[rep] = calculateStockSalesSummary(stockSales.filter(sale => sale.salesrep === rep), rep);
    });
    stockSalesSummary['All'] = calculateStockSalesSummary(stockSales, "All");

    return { salesReps, stockSalesSummary };
}

function calculateStockSalesSummary(data, rep) {
    const currentDate = new Date();
    const summary = {
        last30Days: { amount: 0, count: 0 },
        last60Days: { amount: 0, count: 0 },
        last90Days: { amount: 0, count: 0 },
        last6Months: { amount: 0, count: 0 },
        last12Months: { amount: 0, count: 0 },
        last24Months: { amount: 0, count: 0 },
        rep,
    };

    data.forEach(sale => {
        const saleDate = new Date(sale.trandate);
        const daysDiff = Math.floor((currentDate - saleDate) / (1000 * 60 * 60 * 24));
        const amount = parseFloat(sale.total) || 0;
        if (daysDiff <= 30) { 
            updateSummary(summary.last30Days, amount); 
        }
        if (daysDiff <= 60) { updateSummary(summary.last60Days, amount); }
        if (daysDiff <= 90) { updateSummary(summary.last90Days, amount); }
        if (daysDiff <= 180) { updateSummary(summary.last6Months, amount); }
        if (daysDiff <= 365) { updateSummary(summary.last12Months, amount); }
        if (daysDiff <= 730) { updateSummary(summary.last24Months, amount); }
    });

    return summary;
}

function updateSummary(summaryItem, amount) {
    summaryItem.amount += amount;
    summaryItem.count += 1;
}