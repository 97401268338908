import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import CollapsibleCard from '../Shared/CollapsibleCard';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SORT_ASC, SORT_DESC } from '../../containers/CustomerContainer/constants';
import { formatCurrency, formatNumber, formatPercentage } from '../../utils/currencyFormatter';
import { getColorClass, getLRQvsQ1ColorClass } from '../../utils/colorUtils';
import { debounce } from '../../utils/debounce';

const PAGE_SIZE = 20;

const HEADERS = [
  { label: 'Account', key: 'entity', colSpan: 3, formatter: value => value || 'N/A', type: 'string' },
  { label: '$ L12M', key: 'l12m', formatter: formatCurrency, className: 'text-right', type: 'number' },
  { label: 'L12M/P12M', key: 'l12mVsP12m', formatter: (value) => (
    <span className={getColorClass(value)}>{formatPercentage(value)}</span>
  ), className: 'text-right', type: 'number' },
  { label: 'RQ/LYRQ', key: 'rqVsLyrq', formatter: (value) => (
    <span className={getColorClass(value)}>{formatPercentage(value)}</span>
  ), className: 'text-right', type: 'number' },
  { label: 'LM/LYLM', key: 'lmVsLylm', formatter: (value) => (
    <span className={getColorClass(value)}>{formatPercentage(value)}</span>
  ), className: 'text-right', type: 'number' },
  { label: 'Date of Stock', key: 'dateOfStock', formatter: value => value || 'N/A', type: 'date' },
  { label: '$ Category', key: 'category', formatter: formatNumber, className: 'text-right', type: 'number' },
  { label: 'LRQ', key: 'lrq', formatter: formatNumber, className: 'text-right', type: 'number' },
  { label: 'Q-1', key: 'q1', formatter: formatNumber, className: 'text-right', type: 'number' },
  { label: 'Q-2', key: 'q2', formatter: formatNumber, className: 'text-right', type: 'number' },
  { label: 'Q-3', key: 'q3', formatter: formatNumber, className: 'text-right', type: 'number' },
  { label: 'Q-4', key: 'q4', formatter: formatNumber, className: 'text-right', type: 'number' },
  { label: 'LRQvsQ-1', key: 'lrqVsQ1', formatter: (value) => (
    <span className={getLRQvsQ1ColorClass(value)}>{value > 0 ? '+' : ''}{formatNumber(value)}</span>
  ), className: 'text-right', type: 'number' },
  { label: 'Favorite', key: 'favorite', formatter: value => value ? <FontAwesomeIcon icon={faStar} className="text-warning" /> : '', type: 'boolean' },
  { label: '$ Cohort', key: 'cohort', formatter: value => value || 'N/A', type: 'string' },
  { label: '$ Top 20%', key: 'top20', formatter: value => value || "N/A", type: 'string' },
  { label: '$ Goal', key: 'goal', formatter: value => value || 'N/A', type: 'string' },
];

function PerformanceByAccount({ data, selectedSalesRep }) {
  const [sortColumn, setSortColumn] = useState('favorite');
  const [sortDirection, setSortDirection] = useState(SORT_ASC);
  const [filter, setFilter] = useState('');
  const [debouncedFilter, setDebouncedFilter] = useState('');
  const [displayedData, setDisplayedData] = useState([]);
  const tableContainerRef = useRef();

  const debouncedSetFilter = useCallback(
    debounce((value) => setDebouncedFilter(value), 300),
    []
  );

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    debouncedSetFilter(value);
  };

  const clearFilter = (e) => {
    e.stopPropagation();
    setFilter('');
    setDebouncedFilter('');
  };

  const filteredData = useMemo(() => {
    return (selectedSalesRep && selectedSalesRep !== 'All' ? data?.filter(account => account?.salesRep === selectedSalesRep) : data)
      ?.filter(account => account?.entity?.toLowerCase().includes(debouncedFilter.toLowerCase()));
  }, [data, selectedSalesRep, debouncedFilter]);

  const sortedData = useMemo(() => {
    const columnType = HEADERS.find(header => header.key === sortColumn)?.type;
    return [...(filteredData || [])].sort((a, b) => {
      const aValue = a?.[sortColumn];
      const bValue = b?.[sortColumn];
      
      switch (columnType) {
        case 'date':
          return sortDirection === SORT_ASC 
            ? new Date(aValue) - new Date(bValue)
            : new Date(bValue) - new Date(aValue);
        case 'boolean':
          return sortDirection === SORT_ASC 
            ? (aValue === bValue ? 0 : aValue ? -1 : 1)
            : (aValue === bValue ? 0 : aValue ? 1 : -1);
        case 'number':
          return sortDirection === SORT_ASC ? aValue - bValue : bValue - aValue;
        default: // 'string' or any other type
          return sortDirection === SORT_ASC
            ? aValue?.localeCompare(bValue)
            : bValue?.localeCompare(aValue);
      }
    });
  }, [filteredData, sortColumn, sortDirection]);

  useEffect(() => {
    setDisplayedData(sortedData.slice(0, PAGE_SIZE));
  }, [sortedData]);

  const loadMoreData = useCallback(() => {
    const nextData = sortedData.slice(displayedData.length, displayedData.length + PAGE_SIZE);
    setDisplayedData(prevData => [...prevData, ...nextData]);
  }, [sortedData, displayedData]);

  useEffect(() => {
    const handleScroll = () => {
      const container = tableContainerRef.current;
      if (container) {
        const { scrollTop, clientHeight, scrollHeight } = container;
        if (scrollHeight - scrollTop <= clientHeight * 1.5) {
          loadMoreData();
        }
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadMoreData]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === SORT_ASC ? SORT_DESC : SORT_ASC);
    } else {
      setSortColumn(column);
      setSortDirection(SORT_ASC);
    }
  };

  const renderSortIcon = (column) => {
    if (column !== sortColumn) return <FontAwesomeIcon icon={faSort} />;
    return sortDirection === SORT_ASC ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <CollapsibleCard 
      title="Performance by Account"
      filter={(
        <div className="position-relative performance-by-account-filter-wrapper">
          <input
            type="text"
            className="form-control performance-by-account-filter"
            placeholder="Filter by Account"
            value={filter}
            onChange={handleFilterChange}
            onClick={stopPropagation}
          />
          {filter && (
            <FontAwesomeIcon
              icon={faTimes}
              className="clear-filter-icon"
              onClick={clearFilter}
            />
          )}
        </div>
      )}
      containerClassName="performance-by-account"
    >
      <div className="performance-by-account">
        <div className="table-container" ref={tableContainerRef}>
          <Table hover className="font-open-sans-condensed performance-table">
            <thead>
              <tr>
                {HEADERS.map(({ label, key, colSpan }) => (
                  <th 
                    key={key} 
                    onClick={() => handleSort(key)} 
                    colSpan={colSpan || 1}
                  >
                    {label} {renderSortIcon(key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedData.map(account => (
                <tr key={account?.entity}>
                  {HEADERS.map(({ key, colSpan, formatter, className }) => (
                    <td key={key} className={`font-16 ${className || ''}`} colSpan={colSpan || 1}>
                      {formatter(account?.[key])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </CollapsibleCard>
  );
}

export default PerformanceByAccount;