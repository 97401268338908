import {
    LOGOUT,
    LOGIN,
    PASSWORD_RESET,
    EMAIL_RESET,
    DELETE_ADDRESS,
    GET_STATES,
    GET_COUNTRIES,
    UPDATE_ADDRESS,
    CREATE_ADDRESS,
    GET_ONE_ADDRESS,
    GET_ALL_ADDRESSES,
    RESET_CUSTOMER_DATA,
    RESET_SELECTED_ADDRESS,
    GET_ACCOUNT_INFO_ACTION_TYPES,
    GET_ACCOUNT_ORDER_ACTION_TYPES,
    UPDATE_ACCOUNT_INFO_ACTION_TYPES,
    CREATE_ORDER_ACTION_TYPES,
    GET_SHIPPING_DETAILS_ACTION_TYPES,
    ACCOUNT_CREATE_ORDER_ACTION_TYPES,
    GET_ONE_ORDER,
    RESET_ACCOUNT_INFO,
    RESET_STATES,
    GET_TRANSACTION_HISTORY,
    GET_OPEN_TRANSACTIONS,
    GET_TRANSACTION_DETAILS,
    GET_ORDER_HISTORY,
    STRIPE_SESSION,
    GET_TRANSACTIONAL_DATA,
    GET_CUSTOMER_DATA,
    RESET_TRANSACTIONAL_CUSTOMER_DATA,
    GET_STORE_LIST,
    GET_TASKS_CONTACT_NOTES,
    RESET_TASKS_CONTACT_NOTES,
    GET_ALL_TRANSACTIONS_DATA,
    RESET_ALL_TRANSACTIONS_DATA,
    GET_STOCK_SALES_SUMMARY,
    RESET_STOCK_SALES_SUMMARY,
} from './constants';
import AuthManager from '../../utils/AuthManager';
import CartManager from '../../utils/CartManager';
import PricingTypeManager from '../../utils/PricingTypeManager';
import { processData, processStockSalesSummary } from '../../utils/dataProcessing';

const initialData = {
    isFetching: false,
    addresses: {},
    selectedAddress: {},
    countries: {},
    states: {},
    accountInfo: {},
    orderData: {},
    shippingMethods: {},
    transactionHistory: {},
    openTransactions: {},
    transactionDetails: {},
    orderHistory: {},
    transactionalData: {},
    customerData: {},
    isFetchingTransactionalData: false,
    isFetchingCustomerData: false,
    isFetchingStoreList: false,
    storeList: [],
    taskContactNotes: {},
    isFetchingTaskContactNotes: false,
    allTransactionsData: {},
    isFetchingAllTransactionsData: false,
    stockSalesSummary: {},
    isFetchingStockSalesSummary: false,
};

export default function customerReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case LOGIN.request:
        case LOGOUT.request:
        case PASSWORD_RESET.request:
        case EMAIL_RESET.request:
        case DELETE_ADDRESS.request:
        case GET_STATES.request:
        case GET_ALL_ADDRESSES.request:
        case GET_ONE_ADDRESS.request:
        case GET_COUNTRIES.request:
        case UPDATE_ADDRESS.request:
        case CREATE_ADDRESS.request:
        case GET_ACCOUNT_INFO_ACTION_TYPES.request:
        case UPDATE_ACCOUNT_INFO_ACTION_TYPES.request:
        case GET_ACCOUNT_ORDER_ACTION_TYPES.request:
        case GET_ONE_ORDER.request:
        case CREATE_ORDER_ACTION_TYPES.request:
        case GET_SHIPPING_DETAILS_ACTION_TYPES.request:
        case ACCOUNT_CREATE_ORDER_ACTION_TYPES.request:
        case GET_TRANSACTION_HISTORY.request:
        case GET_OPEN_TRANSACTIONS.request:
        case GET_TRANSACTION_DETAILS.request:
        case GET_ORDER_HISTORY.request:
        case STRIPE_SESSION.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_TRANSACTIONAL_DATA.request:
            return {
                ...state,
                isFetchingTransactionalData: true,
            };
        case GET_CUSTOMER_DATA.request:
            return {
                ...state,
                isFetchingCustomerData: true,
            };
        case GET_STORE_LIST.request:
            return {
                ...state,
                isFetchingStoreList: true,
            };
        case GET_TASKS_CONTACT_NOTES.request:
            return {
                ...state,
                isFetchingTaskContactNotes: true,
            };
        case GET_ALL_TRANSACTIONS_DATA.request:
            return {
                ...state,
                isFetchingAllTransactionsData: true,
            };
        case GET_ALL_TRANSACTIONS_DATA.success:
            return {
                ...state,
                allTransactionsData: processData(payload),
                isFetchingAllTransactionsData: false,
            };
        case GET_ALL_TRANSACTIONS_DATA.failure:
            return {
                ...state,
                isFetchingAllTransactionsData: false,
            };
        case RESET_ALL_TRANSACTIONS_DATA:
            return {
                ...state,
                allTransactionsData: {},
            };
        case LOGIN.success:
            AuthManager.setToken(payload.access_token);
            PricingTypeManager.set('Retail');
            // CartManager.remove();

            return {
                ...state,
                isFetching: false,
            };

        case LOGOUT.success:
            AuthManager.remove();
            CartManager.remove();

            return {
                ...state,
                isFetching: false,
            };

        case GET_ALL_ADDRESSES.success:
        case GET_ALL_ADDRESSES.failure:
            return {
                ...state,
                isFetching: false,
                addresses: payload,
            };

        case GET_ONE_ADDRESS.success:
        case GET_ONE_ADDRESS.failure:
        case UPDATE_ADDRESS.success:
        case UPDATE_ADDRESS.failure:
        case DELETE_ADDRESS.success:
        case DELETE_ADDRESS.failure:
            return {
                ...state,
                isFetching: false,
                selectedAddress: payload,
            };

        case GET_COUNTRIES.success:
        case GET_COUNTRIES.failure:
            return {
                ...state,
                isFetching: false,
                countries: payload,
            };

        case GET_STATES.success:
        case GET_STATES.failure:
            return {
                ...state,
                isFetching: false,
                states: payload,
            };
        case CREATE_ORDER_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                orderData: payload,
            };
        case GET_SHIPPING_DETAILS_ACTION_TYPES.success:
            return {
                ...state,
                shippingMethods: payload,
            };

        case GET_ACCOUNT_INFO_ACTION_TYPES.success:
            AuthManager.setAccountInfo(payload.data);
            CartManager.set(payload.data.cart_id);
            return {
                ...state,
                accountInfo: payload.data,
                isFetching: false,
            };
        case GET_ACCOUNT_ORDER_ACTION_TYPES.success:
            return {
                ...state,
                orderData: payload.data,
                isFetching: false,
            };
        case GET_ONE_ORDER.success:
            return {
                ...state,
                orderData: payload.data,
                isFetching: false,
            };
        case ACCOUNT_CREATE_ORDER_ACTION_TYPES.success:
            return {
                ...state,
                order: payload,
                isFetching: false,
            };

        case GET_OPEN_TRANSACTIONS.success:
            return {
                ...state,
                openTransactions: payload,
                isFetching: false,
            };

        case GET_TRANSACTION_HISTORY.success:
            return {
                ...state,
                transactionHistory: payload,
                isFetching: false,
            };

        case GET_ORDER_HISTORY.success:
            return {
                ...state,
                orderHistory: payload,
                isFetching: false,
            };

        case GET_TRANSACTION_DETAILS.success:
            return {
                ...state,
                transactionDetails: payload,
                isFetching: false,
            };
        case GET_TRANSACTIONAL_DATA.success:
            return {
                ...state,
                transactionalData: payload,
                isFetchingTransactionalData: false,
            };
        case GET_CUSTOMER_DATA.success:
            return {
                ...state,
                customerData: payload?.data?.[0],
                isFetchingCustomerData: false,
            };
        case GET_STORE_LIST.success:
            return {
                ...state,
                storeList: payload?.CustomerInfo || [],
                isFetchingStoreList: false,
            };
        case GET_TASKS_CONTACT_NOTES.success:
            return {
                ...state,
                taskContactNotes: payload,
                isFetchingTaskContactNotes: false,
            };
        case PASSWORD_RESET.success:
        case EMAIL_RESET.success:
        case LOGIN.failure:
        case LOGOUT.failure:
        case PASSWORD_RESET.failure:
        case EMAIL_RESET.failure:
        case GET_ACCOUNT_INFO_ACTION_TYPES.failure:
        case UPDATE_ACCOUNT_INFO_ACTION_TYPES.failure:
        case GET_ACCOUNT_ORDER_ACTION_TYPES.failure:
        case GET_ONE_ORDER.failure:
        case UPDATE_ACCOUNT_INFO_ACTION_TYPES.success:
        case CREATE_ADDRESS.success:
        case CREATE_ADDRESS.failure:
        case CREATE_ORDER_ACTION_TYPES.failure:
        case GET_SHIPPING_DETAILS_ACTION_TYPES.failure:
        case ACCOUNT_CREATE_ORDER_ACTION_TYPES.failure:
        case GET_TRANSACTION_HISTORY.failure:
        case GET_OPEN_TRANSACTIONS.failure:
        case GET_TRANSACTION_DETAILS.failure:
        case GET_ORDER_HISTORY.failure:
        case STRIPE_SESSION.success:
        case STRIPE_SESSION.failure:
            return {
                ...state,
                isFetching: false,
            };
        case GET_TRANSACTIONAL_DATA.failure:
            return {
                ...state,
                isFetchingTransactionalData: false,
            };
        case GET_CUSTOMER_DATA.failure:
            return {
                ...state,
                isFetchingCustomerData: false,
            };
        case GET_STORE_LIST.failure:
            return {
                ...state,
                isFetchingStoreList: false,
            };
        case GET_TASKS_CONTACT_NOTES.failure:
            return {
                ...state,
                isFetchingTaskContactNotes: false,
            };
        case RESET_CUSTOMER_DATA:
            return {
                ...state,
                ...initialData,
            };
        case RESET_TRANSACTIONAL_CUSTOMER_DATA:
            return {
                ...state,
                transactionalData: {},
                customerData: {},
                storeList: [],
            };
        case RESET_TASKS_CONTACT_NOTES:
            return {
                ...state,
                taskContactNotes: {},
            };
        case RESET_SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress: {},
            };
        case RESET_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: {},
            };
        case RESET_STATES:
            return {
                ...state,
                states: {},
            };

        case GET_STOCK_SALES_SUMMARY.request:
            return {
                ...state,
                isFetchingStockSalesSummary: true,
            };
        case GET_STOCK_SALES_SUMMARY.success:
            return {
                ...state,
                stockSalesSummary: processStockSalesSummary(payload),
                isFetchingStockSalesSummary: false,
            };
        case GET_STOCK_SALES_SUMMARY.failure:
            return {
                ...state,
                isFetchingStockSalesSummary: false,
            };
        case RESET_STOCK_SALES_SUMMARY:
            return {
                ...state,
                stockSalesSummary: {},
            };

        default:
            return state;
    }
}

