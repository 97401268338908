import React from 'react';
import CollapsibleCard from '../Shared/CollapsibleCard';
import { Table } from 'reactstrap';

function StoreDistribution({ data }) {
  const categories = Array.from({ length: 10 }, (_, i) => i + 1);
  const quarters = [{label: 'LRQ', value: 'LRQ'}, {label: 'Q - 1', value: 'Q1'}, {label: 'Q - 2', value: 'Q2'}, {label: 'Q - 3', value: 'Q3'}, {label: 'Q - 4', value: 'Q4'}]

  return (
    <CollapsibleCard title="Store Distribution">
      <div className="store-distribution">
        <div className="table-responsive">
          <Table hover className="">
            <thead>
              <tr>
                <th className="font-weight-bold font-open-sans-condensed">Category</th>
                {quarters.map(quarter => (
                  <th key={quarter?.value} className="font-weight-bold font-open-sans-condensed">{quarter?.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {categories.map(category => (
                <tr key={category}>
                  <td className="font-16 font-weight-bold font-universe">{category}</td>
                  {quarters.map(quarter => (
                    <td key={`${category}-${quarter?.value}`} className="font-16 font-open-sans-condensed">
                      {data?.[quarter?.value]?.[category - 1] || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </CollapsibleCard>
  );
}

export default StoreDistribution;